/* eslint-disable complexity */
import { useContext, useEffect } from 'preact/hooks';
import { createRef } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { noop } from '../../../helpers/noop';
import { POLL_TYPES } from '../../../helpers/constants';

import { PollContext } from '../../../polls/poll.component';
import { enableEditableContent } from '../../../helpers/editable-content';
import PollTextOption from './pollTextOption.component';
import PollImageOption from './pollImageOption.component';
import TriviaImageOption from './triviaImageOption.component';
import TriviaTextOption from './triviaTextOption.component';
import PollH2HImageOption from '../../H2H/pollH2HImageOption.component';
import PollH2HTextOption from '../../H2H/pollH2HTextOption.component';
import styleSheet from '../poll.module.scss';
import h2hStyleSheet from '../../H2H/h2h.module.scss';

const styles = styleSheet.locals || {};
// Poll styles copy to h2h options
const h2hStyles = h2hStyleSheet.locals || {};

/**
 * Component for a single poll option
 *
 * @param {object} props poll option props
 * @param {object} props.option poll option data
 * @param {object} props.optionCls css classes
 * @param {boolean} props.showingResults poll is showing results
 * @param {object} props.transitionStyles transition styles
 * @param {string} props.transitionState transition state
 * @param {number} props.optionsVisibleDelay delay for visibility transition
 * @param {boolean} props.isSelected if option is selected by user
 * @param {boolean} props.isAnswered if option has been chosen as an answer by user
 * @param {Function} props.onSelect callback when option has been selected by user
 * @param {number} props.optionImageWidth image width for ODIR
 * @returns {Function} <PollOption />
 */
function PollOption({
    option,
    showingResults = false,
    transitionStyles,
    transitionState,
    optionsVisibleDelay,
    isSelected = false,
    isAnswered = false,
    onSelect = noop,
    optionImageWidth
}) {
    const {
        poll,
        selectedOptions,
        pollOpen,
        pollUserAnswer = null,
        showVotesCounter = false,
        isPreview,
        hasOptionImages,
        onPreviewEditChange
    } = useContext(PollContext);
    const optionTextRef = createRef();

    const isTrivia =
        (poll?.type === POLL_TYPES.TRIVIA ||
            poll?.type === POLL_TYPES.FREE_TEXT) ??
        false;

    const isFreeText = poll?.type === POLL_TYPES.FREE_TEXT;

    const isH2H = poll?.type === POLL_TYPES.H2H;

    const changeEditableData = (field, value) => {
        onPreviewEditChange?.({
            questions: [
                {
                    options: [{ ...option, [field]: value }]
                }
            ]
        });
    };

    useEffect(() => {
        if (isPreview) {
            enableEditableContent(
                optionTextRef.current,
                'text',
                changeEditableData
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!option) {
        return '';
    }

    let activationClasses;
    if (isH2H) {
        activationClasses = classNames(h2hStyles.pollOption, {
            [h2hStyles.pollOptionLarge]: showingResults,
            [h2hStyles.pollOptionNoHover]: !pollOpen || pollUserAnswer,
            [h2hStyles.pollOptionSelected]: isSelected || isAnswered,
            [h2hStyles.pollOptionEnded]: !pollOpen || pollUserAnswer,
            [h2hStyles.pollOptionResults]: showingResults,
            [h2hStyles.pollOptionSubmitted]:
                !pollOpen && showingResults && (isSelected || isAnswered),
            [h2hStyles.pollOptionBig]:
                ((pollUserAnswer && pollOpen) || !pollOpen) &&
                showVotesCounter &&
                !hasOptionImages
        });
    } else {
        let maxSelection = poll?.questions?.[0]?.options_selection_limit ?? 1;

        // option_selection_limit is not supported by BE in trivia
        // so have added workaround for now.
        if (isTrivia) {
            maxSelection = poll?.questions?.[0]?.correct_options?.length ?? 1;
        }

        //Poll
        activationClasses = classNames(styles.pollOption, {
            [styles.pollOptionLarge]: !hasOptionImages,
            [styles.pollOptionNoHover]: !pollOpen || pollUserAnswer,
            [styles.pollOptionSelected]: isSelected || isAnswered,
            [styles.pollOptionDisabled]:
                !pollUserAnswer &&
                !isSelected &&
                selectedOptions.length === maxSelection,
            [styles.pollOptionEnded]: !pollOpen || pollUserAnswer,
            [styles.pollOptionResults]: showingResults,
            [styles.pollOptionSubmitted]:
                !pollOpen && showingResults && (isSelected || isAnswered),
            [styles.pollOptionBig]:
                ((pollUserAnswer && pollOpen) || !pollOpen) &&
                showVotesCounter &&
                !hasOptionImages,
            [styles.pollOptionImage]: hasOptionImages
        });
    }

    return (
        <button
            className={classNames(activationClasses, {
                [styles.pollOptionFreetext]: isFreeText
            })}
            title={option.text}
            onClick={() => !isPreview && onSelect(option)}
        >
            {poll.type === POLL_TYPES.POLL &&
                (hasOptionImages ? (
                    <PollImageOption
                        option={option}
                        isSelected={isSelected}
                        isAnswered={isAnswered}
                        showingResults={showingResults}
                        transitionStyles={transitionStyles}
                        transitionState={transitionState}
                        optionsVisibleDelay={optionsVisibleDelay}
                        optionTextRef={optionTextRef}
                        optionImageWidth={optionImageWidth}
                    />
                ) : (
                    <PollTextOption
                        option={option}
                        isSelected={isSelected}
                        isAnswered={isAnswered}
                        showingResults={showingResults}
                        transitionStyles={transitionStyles}
                        transitionState={transitionState}
                        optionsVisibleDelay={optionsVisibleDelay}
                        optionTextRef={optionTextRef}
                    />
                ))}

            {isH2H &&
                (hasOptionImages ? (
                    <PollH2HImageOption
                        option={option}
                        isSelected={isSelected}
                        isAnswered={isAnswered}
                        showingResults={showingResults}
                        transitionStyles={transitionStyles}
                        transitionState={transitionState}
                        optionsVisibleDelay={optionsVisibleDelay}
                        optionTextRef={optionTextRef}
                        optionImageWidth={optionImageWidth}
                    />
                ) : (
                    <PollH2HTextOption
                        option={option}
                        isSelected={isSelected}
                        isAnswered={isAnswered}
                        showingResults={showingResults}
                        transitionStyles={transitionStyles}
                        transitionState={transitionState}
                        optionsVisibleDelay={optionsVisibleDelay}
                        optionTextRef={optionTextRef}
                    />
                ))}

            {isTrivia &&
                (!hasOptionImages ? (
                    <TriviaTextOption
                        option={option}
                        showingResults={showingResults}
                        transitionStyles={transitionStyles}
                        transitionState={transitionState}
                        optionsVisibleDelay={optionsVisibleDelay}
                        optionTextRef={optionTextRef}
                    />
                ) : (
                    <TriviaImageOption
                        option={option}
                        showingResults={showingResults}
                        transitionStyles={transitionStyles}
                        transitionState={transitionState}
                        optionsVisibleDelay={optionsVisibleDelay}
                        optionTextRef={optionTextRef}
                        optionImageWidth={optionImageWidth}
                        isFreeText={isFreeText}
                    />
                ))}
        </button>
    );
}

PollOption.propTypes = {
    option: PropTypes.object.isRequired,
    showingResults: PropTypes.bool,
    transitionStyles: PropTypes.object,
    transitionState: PropTypes.string,
    optionsVisibleDelay: PropTypes.number,
    isSelected: PropTypes.bool,
    isAnswered: PropTypes.bool,
    onSelect: PropTypes.func,
    optionImageWidth: PropTypes.number.isRequired
};

export default PollOption;
