import { useContext } from 'preact/hooks';
import PropTypes from 'prop-types';

import { getImageUrl } from '../../helpers/images';

import ThumbIcon from '../shared-components/icons/ThumbIcon';
import WinnerIcon from '../shared-components/icons/WinnerIcon';

import styleSheet from '../H2H/h2h.module.scss';
import { PollContext } from '../../polls/poll.component';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for a single poll option
 *
 * @param {object} props poll option props
 * @param {object} props.option poll option data
 * @param {boolean} props.showingResults poll is showing results
 * @param {object} props.transitionStyles transition styles
 * @param {string} props.transitionState transition state
 * @param {number} props.optionsVisibleDelay delay for visibility transition
 * @param {boolean} props.isSelected if option is selected by user
 * @param {boolean} props.isAnswered if option has been chosen as an answer by user
 * @param {object} props.optionTextRef option text node reference
 * @param {number} props.optionImageWidth image width for ODIR
 * @returns {Function} <PollH2HImageOption />
 */
function PollH2HImageOption({
    option,
    showingResults = false,
    transitionStyles,
    transitionState,
    optionsVisibleDelay,
    isSelected = false,
    isAnswered = false,
    optionTextRef,
    optionImageWidth
}) {
    const { t } = useTranslation();
    const { poll, isPreview, showVotesCounter } = useContext(PollContext);
    const editableAttr = isPreview ? { contentEditable: true } : {};
    const barTransitionStyles = {
        entered: { maxWidth: '100%' }
    };
    const winnerTransitionStyles = {
        entered: { opacity: 1, transform: 'scale(1)' }
    };

    /* eslint-disable no-magic-numbers */
    return (
        <>
            {(option.image_url || option.image_data_preview) && (
                <div className={styles.pollOptionImg}>
                    <img
                        src={getImageUrl(option.image_url, optionImageWidth)}
                    />

                    {(isSelected || isAnswered) && (
                        <ThumbIcon className={styles.pollThumbIcon} />
                    )}

                    {showingResults && option.times_selected !== null && (
                        <div
                            className={styles.pollOptionData}
                            style={{
                                ...transitionStyles[transitionState],
                                transitionDelay: `${optionsVisibleDelay}ms`
                            }}
                        >
                            <div
                                className={styles.pollOptionBar}
                                style={{
                                    width: `${option.percentage}%`,
                                    ...barTransitionStyles[transitionState],
                                    transitionDelay: `${optionsVisibleDelay}ms`
                                }}
                            />
                            {option.winner && poll?.state === 'closed' && (
                                <WinnerIcon
                                    className={styles.pollOptionWinnerIcon}
                                    style={
                                        winnerTransitionStyles[transitionState]
                                    }
                                />
                            )}
                            <div className={styles.pollOptionDataText}>
                                <div className={styles.pollOptionPercentage}>
                                    {option.percentage}%
                                </div>
                                {showVotesCounter && (
                                    <div className={styles.pollOptionCounter}>
                                        {option.times_selected}{' '}
                                        {t('common.votes')}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div className={styles.pollOptionText}>
                <span
                    className={styles.pollOptionLabel}
                    {...editableAttr}
                    ref={optionTextRef}
                >
                    {option.text}
                </span>
            </div>
        </>
    );
}

PollH2HImageOption.propTypes = {
    option: PropTypes.object.isRequired,
    showingResults: PropTypes.bool,
    transitionStyles: PropTypes.object,
    transitionState: PropTypes.string,
    optionsVisibleDelay: PropTypes.number,
    isSelected: PropTypes.bool,
    isAnswered: PropTypes.bool,
    optionTextRef: PropTypes.object,
    optionImageWidth: PropTypes.number.isRequired
};

export default PollH2HImageOption;
