import Cookies from 'js-cookie';
import { namespace } from './namespaces';

const defaultAttributes = {
    secure: true,
    sameSite: 'strict'
};

/**
 * Determine Root Domain
 *
 * We want to determine the root domain of the current page so we can hoist
 * cookies. In essence, we want to ensure we're setting first party cookies on
 * example.com rather than www.example.com or other.sub.domain.example.com.
 *
 * This code works its way backwards through a hostname (e.g. starting with .com
 * first) until the browser is able to successfully set and retrieve a cookie.
 *
 * Falls back to hostname when no root domain can be determined.
 *
 * @returns { string } - the domain to set cookies on
 */
function determineRootDomain() {
    if (typeof window === 'undefined') {
        return null;
    }

    const hostnameTokens = window.location.hostname.split('.');
    /**
     * Generates an array of possible domains. Returned array looks something
     * like this:
     *
     * [ "com", "example.com", "www.example.com" ]
     * or
     * [ "uk", "co.uk" "example.co.uk" ]
     */
    const possibleDomains = hostnameTokens.reduceRight((accumulator, token) => {
        accumulator.push(
            `${token}${accumulator.length > 0 ? '.' : ''}${accumulator.slice(
                -1
            )}`
        );
        return accumulator;
    }, []);

    /**
     * Loop through the possible domains in order, until a cookie is
     * successfully set and retrieved.
     */
    for (let domain of possibleDomains) {
        setCookie(`${namespace}test`, 'Root domain test', {
            ...defaultAttributes,
            domain
        });

        if (Cookies.get(`${namespace}test`)) {
            Cookies.remove(`${namespace}test`, {
                ...defaultAttributes,
                domain
            });
            return domain;
        }
    }

    return window.location.hostname;
}

defaultAttributes.domain = determineRootDomain();

/**
 * Set Cookie
 *
 * Sets cookie with SDK attributes
 *
 * @param { string } name - the cookie name
 * @param { string } data - the cookie data
 * @param { object } [attributes] - the cookie attributes
 * @returns {string} the cookie value
 */
export function setCookie(name, data, attributes = {}) {
    return Cookies.set(name, data, {
        ...defaultAttributes,
        ...attributes
    });
}

/**
 * Get Cookie
 *
 * Gets a cookie by name
 *
 * @param {string} name - the cookie name
 * @returns {string} the cookie value
 */
export function getCookie(name) {
    return Cookies.get(name);
}

/**
 * Get Atp Media cookie
 *
 * Gets a cookie by name
 
 * @returns {string} the cookie value
 */
export function getAtpMediaCookie() {
    return Cookies.get('access_token');
}
