/* eslint-disable no-magic-numbers */
import { getClientHost } from '../../helpers/client';
import { getOrCreateEncodedUUID } from '../../helpers/user-meta';

/**
 * GET /v1/fan/polls/{poll_id}/questions/{question_id}/weighted
 * Fetches option number of votes per position
 *
 * @param {string} pollId - FEK Fan form ID
 * @param {string} questionId - question ID
 * @returns {Promise} -
 */
export const getOptionsStandingTallies = (pollId, questionId) => {
    const POLL_HOST = `${getClientHost()}${process.env.API_PATH}`;

    const endpoint = `https://${POLL_HOST}/polls/${pollId}/questions/${questionId}/weighted`;

    return new Promise((resolve, reject) => {
        fetch(endpoint)
            .then((response) => {
                if (!response.ok) {
                    console.warn(
                        'Playbook - Top 3 poll metrics request failure.'
                    );
                    return null;
                }

                return response.json();
            })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                console.error(
                    'Playbook - Top 3 poll metrics request error.',
                    error
                );
                reject(error);
            });
    });
};

/**
 * POST /v1/fan/gated/polls/{poll_id}/questions/{question_id}/weighted/answers
 * Sets top 3 options standing
 *
 * @param {string} pollId - Poll ID
 * @param {string} questionId - Question ID
 * @param {object} payload - answer options payload
 * @param {boolean} isGated - is the poll gated?
 * @param {string} authHeader -auth header value
 * @returns {Promise} -
 */
export const submitOptionsStanding = (
    pollId,
    questionId,
    payload,
    isGated,
    authHeader
) => {
    const userId = getOrCreateEncodedUUID();
    const POLL_HOST = `${getClientHost()}${process.env.API_PATH}`;

    const endpoint = isGated
        ? `https://${POLL_HOST}/gated/polls/${pollId}/questions/${questionId}/weighted/answers`
        : `https://${POLL_HOST}/polls/${pollId}/questions/${questionId}/weighted/answers`;

    const headersConfig = {
        'Content-Type': 'application/json'
    };

    if (isGated && authHeader) {
        headersConfig.Authorization = authHeader;
    }

    if (!isGated && userId) {
        headersConfig['X-User-Id'] = userId;
    }

    return new Promise((resolve, reject) => {
        fetch(endpoint, {
            method: 'POST',
            headers: headersConfig,
            body: JSON.stringify({ answer_options: payload })
        })
            .then((response) => {
                return response.json();
            })
            .then((profile) => {
                if (
                    profile.status &&
                    !profile.status.toString().match(/2\d{2}/g)
                ) {
                    // Non sucessful response
                    reject(profile);
                    return;
                }
                resolve(profile);
            })
            .catch((error) => {
                console.error(
                    'Playbook - Error submiting Top 3 poll vote.',
                    error
                );
                reject(error);
            });
    });
};

/**
 * Helper to match poll and standing results for top 3 results in same object.
 * Returns poll options ordered by number of votes.
 *
 * @param {object} poll - poll
 * @param {object} standings - poll standings
 * @returns {object} - poll with standing results
 */
export const mapOptionsAndVoteResults = (poll, standings) => {
    poll.questions[0].options = poll.questions?.[0]?.options.map((option) => {
        const optionTallies = standings.option_standings.length
            ? standings.option_standings.find(
                  (standing) => standing.option_id === option.option_id
              )?.standing_tallies
            : null;

        return {
            ...option,
            first_times_selected:
                optionTallies?.find((item) => item.standing === 1)?.tally ?? 0,
            second_times_selected:
                optionTallies?.find((item) => item.standing === 2)?.tally ?? 0,
            third_times_selected:
                optionTallies?.find((item) => item.standing === 3)?.tally ?? 0
        };
    });

    // Sort poll options by first, second and third position number of votes:
    poll.questions?.[0]?.options?.sort((a, b) => {
        if (b.times_selected !== a.times_selected) {
            return b.times_selected - a.times_selected;
        } else if (b.first_times_selected !== a.first_times_selected) {
            return b.first_times_selected - a.first_times_selected;
        } else if (b.second_times_selected !== a.second_times_selected) {
            return b.second_times_selected - a.second_times_selected;
        }
        return b.third_times_selected - a.third_times_selected;
    });
    return poll;
};
