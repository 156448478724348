import Polls from './polls';
import GuessTheGameweekGames from './guess-the-gameweek';
import Predictions from './predictions';
import { DataCaptureForm } from 'playbook-data-capture-sdk-web';

/**
 * Initialisation of the SDK
 *
 */
function init() {
    window.PlaybookForm = new DataCaptureForm();
    window.PlaybookGtg = new GuessTheGameweekGames();
    window.PlaybookPoll = new Polls();
    window.PlaybookPrediction = new Predictions();

    window.dispatchEvent(new Event('PLAYBOOK_SDK_INIT'));
}

// Expose the init function on the window object
window.initPlaybook = function () {
    window.PlaybookForm = new DataCaptureForm();
    window.PlaybookPoll = new Polls();
    window.PlaybookPrediction = new Predictions();

    window.dispatchEvent(new Event('PLAYBOOK_SDK_INIT'));
};

// These sites are SPA's so need to use init func above
const initOnDocument =
    !window.location.host.includes('atpmedia.tv') &&
    !window.location.host.includes('tennistv');

if (initOnDocument) {
    switch (document.readyState) {
        case 'interactive':
        case 'complete':
            init();
            break;
        default: {
            document.addEventListener('DOMContentLoaded', init);
        }
    }
} else {
    window.dispatchEvent(new Event('PLAYBOOK_READY'));
}
