/**
 * Generates or retrieves a UUID from local storage
 *
 * @returns {string} - UUID to be used as the XUserIDHeader
 */
export const getOrCreateEncodedUUID = () => {
    const uuidKey = 'XUserIDHeader';

    // Check if encoded UUID already exists in local storage
    let encodedUserId = localStorage.getItem(uuidKey);

    if (!encodedUserId) {
        // Generate a new UUID if it doesn't exist
        const userId = self.crypto.randomUUID();
        // Encode the UUID to base64
        encodedUserId = btoa(userId);
        // Save the encoded UUID in local storage
        localStorage.setItem(uuidKey, encodedUserId);
    }

    return encodedUserId;
};
