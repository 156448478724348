/* eslint-disable complexity */
import { useContext } from 'preact/hooks';
import PropTypes from 'prop-types';

import { noop } from '../../helpers/noop';

import Countdown from '../Countdown/Countdown.component';
import PollOptionsList from './pollOptionsList.component';
import PollTotalVotes from '../PollTotalVotes/pollTotalVotes.component';
import SubmitButton from '../shared-components/button/SubmitButton';
import SignInGate from '../shared-components/gate/SignInGate.component';

import { PollContext } from '../../polls/poll.component';
import { POLL_TYPES } from '../../helpers/constants';

import FreeTextTriviaInput from './freeTextTriviaInput.component';

import styleSheet from './poll.module.scss';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component which allows fan to vote for an option
 *
 * @param {object} props poll option props
 * @param {string} props.transitionState transition state
 * @returns {Function} <PollVoteScreen />
 */
function PollVoteScreen({ transitionState }) {
    const {
        poll,
        selectedOptions,
        setSelectedOptions,
        submittingVote = false,
        showVotesCounter = false,
        transitionStyles,
        transitionDelay,
        onVoteSubmit = noop,
        onFreeTextSubmit = noop,
        totalVotes = 0,
        onCountdownEnd,
        isSignedIn,
        isGated
    } = useContext(PollContext);
    const { t } = useTranslation();
    const isArabic = document.documentElement.lang === 'ar';
    const isTrivia = poll?.type === POLL_TYPES.TRIVIA;
    const isFreeText = poll?.type === POLL_TYPES.FREE_TEXT;
    const isVotingPoll = poll?.type === POLL_TYPES.POLL;

    const isClosed = poll?.state === 'closed';

    const handleInputChange = (event) => {
        setSelectedOptions({
            text: event?.target?.value
        });
    };

    const numAnswers = isTrivia
        ? poll?.questions?.[0]?.correct_options?.length
        : isVotingPoll
          ? poll?.questions?.[0]?.options_selection_limit
          : false;

    const remainingSelections = numAnswers - selectedOptions?.length;

    const isDisabled = () => {
        if (isGated && !isSignedIn) {
            return true;
        }

        if (isFreeText) {
            return (
                selectedOptions?.text?.length === 0 || !selectedOptions?.text
            );
        }

        if (isTrivia) {
            return selectedOptions.length === 0 || remainingSelections !== 0;
        }

        if (isVotingPoll) {
            return remainingSelections !== 0;
        }

        return selectedOptions.length === 0;
    };

    return (
        <div className={isGated ? styles.pollWrapperGated : styles.pollWrapper}>
            {!isSignedIn && isGated && (
                <SignInGate
                    type={isTrivia || isFreeText ? 'trivia' : 'poll'}
                    isResults={isClosed}
                />
            )}
            {!isFreeText && <PollOptionsList />}

            {isFreeText && <FreeTextTriviaInput onChange={handleInputChange} />}

            {numAnswers ? (
                <div
                    className={`${styles.pollSelectAnswersLabel} ${
                        remainingSelections === 0 ? styles.pollSlideUp : ''
                    }`}
                >
                    {/* Workaround for incorrect RTL direction in a sentence with variables */}
                    {isArabic
                        ? `
                        ${t('common.remainingSelection_1')} ${t(
                            isTrivia ? 'trivia.option' : 'polling.option',
                            { count: remainingSelections }
                        )} ${remainingSelections} ${t(
                            'common.remainingSelection_2'
                        )}
                    `
                        : t('common.remainingSelection', {
                              number: remainingSelections,
                              option: t(
                                  isTrivia ? 'trivia.option' : 'polling.option',
                                  { count: remainingSelections }
                              )
                          })}
                </div>
            ) : null}

            <div
                className={styles.pollSubmitContainer}
                style={{
                    ...transitionStyles[transitionState],
                    transitionDelay: `${transitionDelay}ms`
                }}
            >
                <SubmitButton
                    onSubmit={isFreeText ? onFreeTextSubmit : onVoteSubmit}
                    disabled={isDisabled()}
                    title={t(
                        isTrivia || isFreeText
                            ? 'trivia.submitTooltip'
                            : 'polling.submitTooltip'
                    )}
                    buttonText={t(
                        isTrivia || isFreeText
                            ? 'trivia.submit'
                            : 'polling.submit'
                    )}
                    loading={submittingVote}
                />
                {showVotesCounter && (
                    <PollTotalVotes
                        votes={totalVotes}
                        style={{
                            ...transitionStyles[transitionState]
                        }}
                        isTrivia={isTrivia}
                    />
                )}
            </div>
            {poll.closes_at && (
                <Countdown
                    text={t('polling.closesIn')}
                    datetime={poll.closes_at}
                    onCountdownEnd={onCountdownEnd}
                    closes={true}
                    leftAligned={true}
                />
            )}
        </div>
    );
}

PollVoteScreen.propTypes = {
    transitionState: PropTypes.string.isRequired
};

export default PollVoteScreen;
