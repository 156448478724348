import { getAtpMediaCookie } from './cookies';

/**
 * Retrieves the authorization header based on the specified SSO (Single Sign-On) token type.
 *
 * @returns {string|boolean} - Returns the authorization header in the format `Bearer <token>`
 *                             if a valid token is found. Returns `false` if no valid token is available.
 */
export const getAuthHeader = () => {
    // Fallback to the mapping logic for certain clients if no auth header is found
    const configMap = [
        {
            condition: () => window.location.host.includes('tennistv'),
            getter: getAtpMediaCookie
        },
        {
            condition: () => window.location.host.includes('ecb'),
            getter: getEcbTokenFromLocalStorage
        },
        {
            condition: () => window.location.host.includes('thehundred'),
            getter: getHundredTokenFromLocalStorage
        },
        {
            condition: () => window.location.host.includes('afl'),
            getter: getAflOktaTokenFromLocalStorage
        },
        {
            condition: () => window.location.host.includes('wta'),
            getter: getWtaTokenFromLocalStorage
        },
        {
            condition: () => window.location.host.includes('spl'),
            getter: getSplTokenFromLocalStorage
        }
    ];

    for (const config of configMap) {
        if (config.condition()) {
            const jwt = config.getter();
            if (jwt) {
                return `Bearer ${jwt}`;
            }
        }
    }

    // Final fallback to localStorage if all else fails - incase a Pulselive ID client has forgotten
    // to configure this properly.
    const fallbackJwt = localStorage.getItem('kc_token') || null;
    return fallbackJwt ? `Bearer ${fallbackJwt}` : false;
};

/**
 * Checks if the user is signed in based on the specified SSO (Single Sign-On) token type.
 *
 * @returns {boolean} - Returns `true` if a valid authorization header is found, indicating the user is signed in.
 *                      Returns `false` if no valid authorization header is available.
 */
export const checkForSignIn = () => {
    const authHeader = getAuthHeader();

    if (authHeader) {
        return true;
    }

    return false;
};

/**
 * Retrieves the `kc_auth_token` in local storage.
 *
 * @returns {string|null} The `kc_auth_token` if found, or `null` if not found or an error occurs.
 */
export function getSplTokenFromLocalStorage() {
    // Retrieve the hundred data from local storage
    const splToken = localStorage.getItem('kc_auth_token');

    // Check if the data exists
    if (!splToken) {
        console.warn('No data found under the key "kc_auth_token".');
        return null;
    }

    return splToken;
}

/**
 * Retrieves the `idToken` from the `ecb_user` key in local storage.
 *
 * This function checks if the `ecb_user` key exists in local storage, parses the
 * stored JSON string into an object, and retrieves the `idToken` property if available.
 *
 * @returns {string|null} The `idToken` if found, or `null` if not found or an error occurs.
 */
export function getEcbTokenFromLocalStorage() {
    // Retrieve the ecb_user data from local storage
    const ecbUserData = localStorage.getItem('ecb_user');

    // Check if the data exists
    if (!ecbUserData) {
        console.warn('No data found under the key "ecb_user".');
        return null;
    }

    try {
        // Parse the JSON string to an object
        const userData = JSON.parse(ecbUserData);

        // Check if idToken exists in the parsed object
        if (userData.idToken) {
            return userData.idToken;
        }
        console.warn('idToken not found in the ecb_user object.');
        return null;
    } catch (error) {
        console.error('Error parsing ecb_user data:', error);
        return null;
    }
}

/**
 * Retrieves the `idToken` from the `hundred-ball_user` key in local storage.
 *
 * This function checks if the `hundred-ball_user` key exists in local storage, parses the
 * stored JSON string into an object, and retrieves the `idToken` property if available.
 *
 * @returns {string|null} The `idToken` if found, or `null` if not found or an error occurs.
 */
export function getHundredTokenFromLocalStorage() {
    // Retrieve the hundred data from local storage
    const hundredUserData = localStorage.getItem('hundred-ball_user');
    // Check if the data exists
    if (!hundredUserData) {
        console.warn('No data found under the key "hundred-ball_user".');
        return null;
    }
    try {
        // Parse the JSON string to an object
        const userData = JSON.parse(hundredUserData);
        // Check if idToken exists in the parsed object
        if (userData.idToken) {
            return userData.idToken;
        }
        console.warn('idToken not found in the hundred object.');
        return null;
    } catch (error) {
        console.error('Error parsing hundred data:', error);
        return null;
    }
}

/**
 * Retrieves the `idToken` from the `okta-token-storage` key in local storage.
 *
 * This function checks if the `okta-token-storage` key exists in local storage, parses the
 * stored JSON string into an object, and retrieves the `idToken` property if available.
 *
 * @returns {string|null} The `idToken` if found, or `null` if not found or an error occurs.
 */
export function getAflOktaTokenFromLocalStorage() {
    const oktaValue = localStorage.getItem('okta-token-storage') || null;

    // Check if the data exists
    if (!oktaValue) {
        console.warn('No data found under the key "okta-token-storage".');
        return null;
    }

    try {
        // Parse the JSON string to an object
        const parsedOktaValue = JSON.parse(oktaValue);

        // Check if idToken exists in the parsed object
        if (parsedOktaValue?.idToken?.idToken) {
            return parsedOktaValue.idToken.idToken;
        }
        console.warn('idToken not found in the afl object.');
        return null;
    } catch (error) {
        console.error('Error parsing afl data:', error);
        return null;
    }
}

/**
 * Retrieves the `kc_token` in local storage.
 *
 * @returns {string|null} The `kc_token` if found, or `null` if not found or an error occurs.
 */
export function getWtaTokenFromLocalStorage() {
    // Retrieve the hundred data from local storage
    const wtaToken = localStorage.getItem('kc_token');

    // Check if the data exists
    if (!wtaToken) {
        console.warn('No data found under the key "kc_token".');
        return null;
    }

    return wtaToken;
}
