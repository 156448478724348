/* eslint-disable id-length */
/* eslint-disable camelcase */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import { useState, useEffect } from 'preact/hooks';
import { createContext } from 'preact';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';

import styleSheet from '../components/Polling/poll.module.scss';
import styleSheetH2H from '../components/H2H/h2h.module.scss';

import PollHeader from '../components/Polling/pollHeader.component';
import PollVoteScreen from '../components/Polling/pollVoteScreen.component';
import PollScheduledScreen from '../components/Polling/pollScheduledScreen.component';
import PollResultsScreen from '../components/Polling/pollResultsScreen.component';
import PollVoteCastScreen from '../components/Polling/pollVoteCastScreen.component';
import PollClosedScreen from '../components/Polling/pollClosedScreen.component';
import PollHero from '../components/Polling/pollHero.component';
import PollSponsor from '../components/Polling/pollSponsor.component';
import H2HVoteScreen from '../components/H2H/h2hVoteScreen.component';
import { DataCaptureFormComponent as Form } from 'playbook-data-capture-sdk-web';

import SubmitButton from '../components/shared-components/button/SubmitButton';
import {
    isOpen,
    isScheduled,
    getUserVote,
    getTotalVotes,
    RESULTS_VISIBILITY,
    pollHasOptionImages,
    sortPollOptions
} from './poll.helpers';
import { getOrCreateEncodedUUID } from '../helpers/user-meta';
import { sendView } from '../helpers/analytics';
import { getClientHost } from '../helpers/client';
import {
    sendCustomEventVoted,
    sendCustomEventLoaded,
    getVoteStatus
} from '../helpers/customEvents';
import { POLL_TYPES } from '../helpers/constants';
import { getAuthHeader, checkForSignIn } from '../helpers/authentication';
import { sendGTMEvent } from '../helpers/gtm';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};
const SHOW_VOTES_THRESHOLD = 1000;
const THREE_SECONDS = 3000;

export const JITTER_TIMEOUT = 2000;
export const PollContext = createContext({});

/**
 * Component for showing a poll
 *
 * @param {object} props poll props
 * @param {object} props.pollData polling data
 * @param {object} props.embeddedFormData form data to embed after polling submit
 * @param {object} props.container poll embed container
 * @param {Function} props.refetch function to refetch poll data
 * @param {boolean} props.isPreview poll embed is a preview
 * @param {Function} props.onPreviewEditChange callback function when contenteditable data changes
 * @param {boolean} props.isMockPoll Is this a unit test render of the poll
 * @param {object} props.colourOverrides colour overrides object
 * @returns {Function} <Poll />
 */
function Poll({
    pollData,
    embeddedFormData,
    container,
    refetch,
    isPreview = false,
    onPreviewEditChange,
    isMockPoll = false,
    colourOverrides
}) {
    const { t } = useTranslation();
    const [poll, setPoll] = useState(pollData);
    const [isSignedIn, setIsSignedIn] = useState(false);

    const isGated = pollData?.gated;
    const authHeader = getAuthHeader();

    const isTrivia = poll?.type === POLL_TYPES.TRIVIA;
    const isFreeText = poll?.type === POLL_TYPES.FREE_TEXT;

    const [selectedOptions, setSelectedOptions] = useState([]);

    const [pollUserAnswer, setPollUserAnswer] = useState(
        getUserVote(isTrivia || isFreeText ? poll.trivia_id : poll.poll_id)
    );
    const [startEmbedAnimation, setStartEmbedAnimation] = useState(false);
    const [startBarsAnimation, setStartBarsAnimation] = useState(false);
    const [submittingVote, setSubmittingVote] = useState(false);
    const [dataCaptureFormSubmitted, setDataCaptureFormSubmitted] =
        useState(false);
    const [goToPollResult, setGoToPollResult] = useState(false);

    const pollOpen = isOpen(poll);
    const pollScheduled = isScheduled(poll);
    const totalVotes = getTotalVotes(poll);
    const showVotesCounter = totalVotes >= SHOW_VOTES_THRESHOLD;
    const hasOptionImages = pollHasOptionImages(poll);

    const isDraft = poll?.state === 'draft';

    const [forceForm, setForceForm] = useState(false);
    const [formComplete, setFormComplete] = useState(false);

    const hasHeroImg = poll?.hero_image;

    /* eslint-disable no-magic-numbers */
    const largePoll = poll?.questions?.[0]?.options.length > 15 || hasHeroImg;

    const { ref } = useInView({
        triggerOnce: true,
        threshold: largePoll ? 0.1 : 0.1,
        onChange: (inView) => {
            if (inView) {
                setStartEmbedAnimation(true);
                setStartBarsAnimation(true);

                sendGTMEvent('playbook_view_event', poll, {
                    playbookUserVoted: pollUserAnswer !== null
                });

                if (!isMockPoll && !isPreview) {
                    // Dont register a view when user cannot use the poll
                    if (isGated && !authHeader) {
                        return;
                    }

                    if (isTrivia || isFreeText) {
                        sendView(
                            'TRIVIA',
                            poll?.trivia_id,
                            isGated,
                            authHeader
                        ).catch((error) => {
                            // Handle the error here
                            console.error('Error sending view:', error);
                        });
                    } else {
                        sendView(
                            'POLL',
                            poll?.poll_id,
                            isGated,
                            authHeader
                        ).catch((error) => {
                            // Handle the error here
                            console.error('Error sending view:', error);
                        });
                    }
                }
            }
        }
    });

    useEffect(() => {
        setIsSignedIn(checkForSignIn());
    }, []);

    useEffect(() => {
        applyPollSettings();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPoll(pollData);
    }, [pollData]);

    useEffect(() => {
        if (!isTrivia) {
            sendCustomEventLoaded(poll, pollUserAnswer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollUserAnswer]);

    useEffect(() => {
        if (isPreview && poll.previewVoted) {
            setPollUserAnswer(poll.questions[0].options[1].option_id);
        } else if (isPreview) {
            setPollUserAnswer(
                getUserVote(isTrivia ? poll.trivia_id : poll.poll_id)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPreview, poll.previewVoted]);

    const applyPollSettings = () => {
        const pollSettingsAttr =
            container.previousElementSibling?.getAttribute('data-fek-settings');
        if (pollSettingsAttr) {
            const settings = JSON.parse(pollSettingsAttr);

            if (settings.primaryColour) {
                container.style.setProperty(
                    '--fek-primary',
                    settings.primaryColour
                );
            }

            if (settings.primaryColourRgb) {
                container.style.setProperty(
                    '--fek-primary-rgb',
                    settings.primaryColourRgb
                );
            }
        } else if (colourOverrides?.hex && colourOverrides?.rgb) {
            container?.style?.setProperty('--fek-primary', colourOverrides.hex);

            container?.style?.setProperty(
                '--fek-primary-rgb',
                colourOverrides.rgb
            );
        }
    };

    const submitPollAnswer = (
        pollId,
        questionId,
        answerIdList,
        isGated,
        authHeader
    ) => {
        const userId = isMockPoll
            ? 'MmNkZDQ2NTAtZDA5NC00MmQ4LTkxNDgtODhiYWZlNTg3MWJh'
            : getOrCreateEncodedUUID();

        const pollEndpoint = isGated
            ? `https://${getClientHost()}${
                  process.env.API_PATH
              }/gated/polls/${pollId}/questions/${questionId}/answers`
            : `https://${getClientHost()}${
                  process.env.API_PATH
              }/polls/${pollId}/questions/${questionId}/answers`;

        const triviaEndpoint = isGated
            ? `https://${getClientHost()}${
                  process.env.API_PATH_TRIVIA
              }/gated/trivia/${pollId}/questions/${questionId}/answers`
            : `https://${getClientHost()}${
                  process.env.API_PATH_TRIVIA
              }/trivia/${pollId}/questions/${questionId}/answers`;

        const data = {
            answer_options: answerIdList.map((answer) => ({
                option_id: answer
            }))
        };

        const body = JSON.stringify(data);

        const headersConfig = {
            'Content-Type': 'application/json'
        };

        if (isGated && authHeader) {
            headersConfig.Authorization = authHeader;
        }

        if (!isGated && userId) {
            headersConfig['X-User-Id'] = userId;
        }

        return fetch(isTrivia || isFreeText ? triviaEndpoint : pollEndpoint, {
            method: 'POST',
            headers: headersConfig,
            body
        });
    };

    if (poll.questions.length === 0) {
        return '';
    }

    const onVoteSubmit = async () => {
        if (isPreview) {
            return;
        }

        if (selectedOptions.length > 0) {
            setSubmittingVote(true);
            setStartBarsAnimation(false);

            const selectedOptionsId = selectedOptions.map(
                (option) => option.option_id
            );

            const submissionResponse = await submitPollAnswer(
                isTrivia ? poll.trivia_id : poll.poll_id,
                poll.questions[0].question_id,
                selectedOptionsId,
                isGated,
                authHeader
            );
            setTimeout(async () => {
                const updatedPoll = await refetch();
                sortPollOptions(updatedPoll);

                setPoll(updatedPoll);
                setPollUserAnswer(selectedOptionsId);
                setTimeout(() => setStartBarsAnimation(true), 0);

                localStorage.setItem(
                    isTrivia ? poll.trivia_id : poll.poll_id,
                    selectedOptionsId
                );

                if (!isTrivia) {
                    sendCustomEventVoted(
                        updatedPoll,
                        selectedOptions,
                        submissionResponse
                    );
                }

                if (!isInstantResults && embeddedFormData) {
                    setForceForm(true);
                }

                sendGTMEvent('playbook_vote_event', poll, {
                    playbookUserVoted: true,
                    playbookUserVoteStatus: getVoteStatus(submissionResponse),
                    playbookUserAnswers: selectedOptions.map(
                        (option) => option.text || option.option_id
                    )
                });
            }, JITTER_TIMEOUT);
        } else {
            console.warn('NO OPTION SELECTED!');
        }
    };

    const onFreeTextSubmit = async () => {
        if (isPreview || !selectedOptions?.text?.length) {
            return;
        }

        setSubmittingVote(true);
        setStartBarsAnimation(false);

        const fanAnswer = selectedOptions.text.trim().toLowerCase();
        const correctAnswer = poll?.questions?.[0]?.correct_options?.[0];
        const incorrectAnswer = poll?.questions?.[0]?.options.find(
            (option) => option.text.trim().toLowerCase() === 'incorrect'
        );

        const isFanCorrect = isAnswerCorrect(fanAnswer, correctAnswer);
        const fanAnswerOptionId = isFanCorrect
            ? [correctAnswer.option_id]
            : [incorrectAnswer.option_id];

        await submitPollAnswer(
            poll.trivia_id,
            poll.questions[0].question_id,
            fanAnswerOptionId,
            isGated,
            authHeader
        );

        setTimeout(async () => {
            const updatedPoll = await refetch();
            sortPollOptions(updatedPoll);

            setPoll(updatedPoll);
            setPollUserAnswer(fanAnswerOptionId);
            setStartBarsAnimation(true);
            localStorage.setItem(poll.trivia_id, fanAnswerOptionId);
            localStorage.setItem(`userAnswer_${poll.trivia_id}`, fanAnswer);
        }, JITTER_TIMEOUT);
    };

    const isAnswerCorrect = (fanAnswer, correctAnswer) => {
        const correctOptionText = correctAnswer?.text.trim().toLowerCase();
        const isExactMatch = fanAnswer === correctOptionText;
        const isAlternativeMatch = correctAnswer?.alternative_texts.some(
            (text) => text.trim().toLowerCase() === fanAnswer
        );

        return isExactMatch || isAlternativeMatch;
    };

    const onCountdownEnd = () => {
        setTimeout(async () => {
            const updatedPoll = await refetch();
            setPoll(updatedPoll);
        }, THREE_SECONDS);
    };

    const isInstantResults =
        poll.questions[0]?.results_visibility ===
        RESULTS_VISIBILITY.AFTER_SUBMISSION;

    const showPollOptions =
        isGated && !isSignedIn && !isDraft ? true : !pollUserAnswer && pollOpen;

    const hasResults = poll.questions[0]?.options.length > 0;
    const resultsVisibility =
        poll.questions[0]?.results_visibility ||
        RESULTS_VISIBILITY.AFTER_SUBMISSION;
    const arePollResultsReady =
        (pollOpen &&
            pollUserAnswer &&
            resultsVisibility === RESULTS_VISIBILITY.AFTER_SUBMISSION) ||
        (!pollOpen && hasResults && !pollScheduled);
    const showScheduledScreen = !pollOpen && pollScheduled;
    const showVotingClosedScreen = !pollOpen && !pollScheduled;

    const showDataCaptureForm =
        arePollResultsReady &&
        embeddedFormData?.state === 'open' &&
        !goToPollResult;

    const showResultsScreen =
        isGated && !isSignedIn
            ? arePollResultsReady && !showDataCaptureForm && isSignedIn
            : arePollResultsReady && !showDataCaptureForm;

    let showVoteCastScreen = false;
    switch (resultsVisibility) {
        case RESULTS_VISIBILITY.AFTER_SUBMISSION:
            showVoteCastScreen = false;
            break;
        case RESULTS_VISIBILITY.AFTER_CLOSING:
            showVoteCastScreen = pollOpen;
            break;
        case RESULTS_VISIBILITY.NEVER:
            showVoteCastScreen = true;
            break;
        default:
    }

    // If its a gated poll and they are not signed in then never show
    // the vote case screen.
    if (isGated && !isSignedIn) {
        showVoteCastScreen = false;
    }

    // Do not show vote case if form is being triggered
    if (forceForm) {
        showVoteCastScreen = false;
    }

    // allows us to ensure a user will always get a form for the after close
    // and never polls - even if page refresh
    if (
        showVoteCastScreen &&
        pollUserAnswer &&
        embeddedFormData &&
        !formComplete
    ) {
        setForceForm(true);
    }

    const pollWidth = poll.settings?.width || '';
    const transitionStyles = {
        entered: { opacity: 1, transform: 'rotate(0deg)' }
    };
    const transitionDelay = 100;
    const transitionLength = 300;
    const submitTransitionDelay =
        (poll.questions?.[0]?.options?.length || 0) * transitionDelay +
        transitionLength;

    const resultCallback = () => {
        setGoToPollResult(true);
        setForceForm(false);
        setFormComplete(true);
    };

    return (
        <PollContext.Provider
            value={{
                poll,
                selectedOptions,
                setSelectedOptions,
                pollOpen,
                startEmbedAnimation,
                submittingVote,
                showVotesCounter,
                transitionStyles,
                submitTransitionDelay,
                onVoteSubmit,
                onFreeTextSubmit,
                totalVotes,
                onCountdownEnd,
                isPreview,
                onPreviewEditChange,
                hasOptionImages,
                pollUserAnswer,
                startBarsAnimation,
                isSignedIn,
                setIsSignedIn,
                authHeader,
                isGated
            }}
        >
            <style>
                {styleSheet.toString()}
                {styleSheetH2H.toString()}
            </style>

            <Transition nodeRef={ref} in={startEmbedAnimation}>
                {(transitionState) => (
                    <>
                        <div
                            data-cy="poll-root"
                            ref={ref}
                            className={classNames(styles.poll, {
                                [styles.pollScheduled]: pollScheduled,
                                [styles.pollClosed]: showVotingClosedScreen
                            })}
                            style={{
                                maxWidth: pollWidth,
                                ...transitionStyles[transitionState]
                            }}
                        >
                            {poll.hero_image_url && <PollHero poll={poll} />}

                            <div className={styles.pollContent}>
                                <PollHeader
                                    poll={poll}
                                    pollScheduled={pollScheduled}
                                />

                                <div className={styles.pollBody}>
                                    {showScheduledScreen && (
                                        <PollScheduledScreen
                                            poll={poll}
                                            pollScheduled={pollScheduled}
                                            onCountdownEnd={onCountdownEnd}
                                        />
                                    )}

                                    {showPollOptions &&
                                        (poll.type === POLL_TYPES.H2H ? (
                                            <H2HVoteScreen
                                                transitionState={
                                                    transitionState
                                                }
                                            />
                                        ) : (
                                            <PollVoteScreen
                                                transitionState={
                                                    transitionState
                                                }
                                            />
                                        ))}

                                    {(showDataCaptureForm || forceForm) && (
                                        <>
                                            <Form
                                                formData={embeddedFormData}
                                                submittedCallback={() => {
                                                    setDataCaptureFormSubmitted(
                                                        true
                                                    );
                                                }}
                                                pollId={poll.poll_id}
                                            />

                                            {dataCaptureFormSubmitted && (
                                                <SubmitButton
                                                    onSubmit={resultCallback}
                                                    buttonText={
                                                        !isInstantResults &&
                                                        !arePollResultsReady
                                                            ? t(
                                                                  'button.viewPollVote'
                                                              )
                                                            : t(
                                                                  'button.viewPollResults'
                                                              )
                                                    }
                                                    title="View poll results"
                                                />
                                            )}
                                        </>
                                    )}

                                    {showResultsScreen &&
                                        !showVoteCastScreen && (
                                            <PollResultsScreen
                                                transitionDelay={
                                                    submitTransitionDelay
                                                }
                                                transitionState={
                                                    transitionState
                                                }
                                                totalVotes={totalVotes}
                                            />
                                        )}

                                    {showVoteCastScreen && pollUserAnswer && (
                                        <PollVoteCastScreen
                                            resultsVisibility={
                                                resultsVisibility
                                            }
                                            transitionDelay={
                                                submitTransitionDelay
                                            }
                                            transitionState={transitionState}
                                        />
                                    )}
                                    {showVotingClosedScreen && (
                                        <PollClosedScreen
                                            resultsVisibility={
                                                resultsVisibility
                                            }
                                            transitionStyles={transitionStyles}
                                            transitionState={transitionState}
                                            isTrivia={isTrivia || isFreeText}
                                        />
                                    )}
                                </div>

                                {poll.sponsor_image_url && (
                                    <PollSponsor poll={poll} />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Transition>
        </PollContext.Provider>
    );
}

Poll.propTypes = {
    pollData: PropTypes.object.isRequired,
    embeddedFormData: PropTypes.object,
    container: PropTypes.node.isRequired,
    refetch: PropTypes.func.isRequired,
    isPreview: PropTypes.bool,
    onPreviewEditChange: PropTypes.func,
    isMockPoll: PropTypes.bool,
    colourOverrides: PropTypes.object
};

export default Poll;
