/* eslint-disable no-magic-numbers */
import { createRef } from 'preact';
import { useContext } from 'preact/hooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getImageUrl } from '../../helpers/images';

import styleSheet from './styles/TopThreeOption.module.scss';
import styleSheetPoll from '../Polling/poll.module.scss';
import { TopThreePollContext } from './TopThreePoll.component';
import { PositionLabel } from './shared-components/PositionLabel.component';

const styles = styleSheet.locals || {};
const stylesPoll = styleSheetPoll.locals || {};

/**
 * Component for a single top three option
 *
 * @param {object} props prediction option props
 * @param {object} props.option prediction option data
 * @param {boolean} props.isSelected if option is selected by user
 * @param {number} props.selectedIndex current option index
 * @returns {Function} <TopThreeOption />
 */
function TopThreeOption({ option, isSelected, selectedIndex }) {
    const { selectedThreeOptions, setSelectedThreeOptions } =
        useContext(TopThreePollContext);

    const optionTextRef = createRef();

    const hasOptionImages = option.image_url || option.image_data_preview;

    const hasEmptyOption = selectedThreeOptions.includes(null);

    const onSelect = (newOption) => {
        const currentOptionIndex = selectedThreeOptions.findIndex(
            (option) => option === null
        );
        const newState = [...selectedThreeOptions];
        newState[currentOptionIndex] = newOption;
        setSelectedThreeOptions(newState);
    };

    if (!option) {
        return '';
    }

    return (
        <>
            <style>{styleSheet.toString()}</style>
            {isSelected && <PositionLabel selectedIndex={selectedIndex} />}
            <button
                className={classNames(
                    stylesPoll.pollOption,
                    styles.optionsSectionOption,
                    {
                        [styles.optionsSectionOptionSelected]:
                            isSelected || !hasEmptyOption,
                        [styles.optionsSectionOptionSelectedBackground]:
                            isSelected && !hasOptionImages,
                        [styles.optionsSectionOptionImage]: hasOptionImages
                    }
                )}
                title={option.text}
                onClick={() => onSelect(option)}
                disabled={isSelected}
            >
                <div
                    className={classNames(styles.optionsSectionContent, {
                        [styles.optionsSectionContentImage]: hasOptionImages
                    })}
                >
                    {hasOptionImages && (
                        <div className={styles.optionsSectionOptionImg}>
                            <img src={getImageUrl(option.image_url, 840)} />
                        </div>
                    )}

                    <div
                        className={classNames(styles.optionsSectionTitle, {
                            [styles.optionsSectionTitleImage]: hasOptionImages
                        })}
                        ref={optionTextRef}
                    >
                        {option.text}
                    </div>
                </div>
            </button>
        </>
    );
}

TopThreeOption.propTypes = {
    option: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    selectedIndex: PropTypes.number
};

export { TopThreeOption };
