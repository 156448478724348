import PropTypes from 'prop-types';
import TriviaTextOption from './PollOption/triviaTextOption.component';
import { useTranslation } from 'react-i18next';

import styleSheet from './freeText.module.scss';
const styles = styleSheet.locals || {};

/**
 * Component for the poll sponsor
 *
 * @param {object} props poll option props
 * @param {string} props.userAnswer incorrect user answer
 * @returns {Function} <PollSponsor />
 */
function TriviaWrongAnswer({ userAnswer }) {
    const { t } = useTranslation();
    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div className={styles.freetextIncorrect}>
                <p className={styles.freetextIncorrectLabel}>
                    {t('trivia.answerIncorrect')}
                </p>
                <TriviaTextOption
                    option={{
                        text: userAnswer
                    }}
                    showCrossIcon={true}
                    isFreetext={true}
                />
            </div>
        </>
    );
}

TriviaWrongAnswer.propTypes = {
    userAnswer: PropTypes.string.isRequired
};

export default TriviaWrongAnswer;
