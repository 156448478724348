import PropTypes from 'prop-types';

import styleSheet from './poll.module.scss';

import { getImageUrl } from '../../helpers/images';

const styles = styleSheet.locals || {};

/**
 * Component for the poll hero
 *
 * @param {object} props poll option props
 * @param {object} props.poll poll option data
 * @param {boolean} props.isVisible - header hero visible, to decrease complexity of main components
 * @returns {Function} <PollHero />
 */
function PollHero({ poll, isVisible = true }) {
    if (!poll.hero_image_url || !isVisible) {
        return null;
    }
    /* eslint-disable no-magic-numbers */
    return (
        <div className={styles.pollHero}>
            <img
                src={getImageUrl(poll.hero_image_url, 1784)}
                alt={poll.hero_image_text || ''}
                className={styles.pollHeroImage}
            />
        </div>
    );
}

PollHero.propTypes = {
    poll: PropTypes.object.isRequired,
    isVisible: PropTypes.bool
};

export default PollHero;
