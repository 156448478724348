import { useContext } from 'preact/hooks';
import PropTypes from 'prop-types';

import { PollContext } from '../../polls/poll.component';
import { useTranslation } from 'react-i18next';

import styleSheet from './trivia.module.scss';

const styles = styleSheet.locals || {};

/**
 * Component for a single poll option
 *
 * @param {object} props poll option props
 * @param {object} props.isFanCorrect poll object
 * @returns {Function} <TriviaAnswerText />
 */
function TriviaAnswerText({ isFanCorrect = {} }) {
    const { t } = useTranslation();
    const { poll } = useContext(PollContext);
    const isArabic = document.documentElement.lang === 'ar';
    const didFanAnswer = isFanCorrect?.didFanAnswer;

    const totalCorrectAnswers = poll?.questions?.[0]?.correct_options?.length;

    const answerText = isFanCorrect?.allCorrect
        ? poll?.questions?.[0]?.correct_answer_text
        : poll?.questions?.[0]?.incorrect_answer_text;

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div className={styles.triviaAnswerTextContainer}>
                {didFanAnswer && (
                    <p className={styles.triviaAnswerText}>{answerText}</p>
                )}

                {totalCorrectAnswers > 1 && didFanAnswer && (
                    <p className={styles.triviaTotalAnswers}>
                        {isArabic
                            ? `${isFanCorrect.correctCount} ${t(
                                  'trivia.remainingAnswers_1'
                              )} ${totalCorrectAnswers} ${t(
                                  'trivia.remainingAnswers_2'
                              )}`
                            : t('trivia.remainingAnswers', {
                                  correct: isFanCorrect.correctCount,
                                  total: totalCorrectAnswers
                              })}
                    </p>
                )}
                {poll?.questions?.[0]?.answer_revealed_information && (
                    <p className={styles.triviaAnswerRevealedInformation}>
                        {poll?.questions?.[0]?.answer_revealed_information}
                    </p>
                )}
            </div>
        </>
    );
}

TriviaAnswerText.propTypes = {
    isFanCorrect: PropTypes.object
};

export default TriviaAnswerText;
