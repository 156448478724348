/* eslint-disable eqeqeq */
/* eslint-disable no-magic-numbers */
import { useState, useRef, useContext } from 'preact/hooks';
import PropTypes from 'prop-types';

import SubmitButton from '../../components/shared-components/button/SubmitButton';

import SignInGate from '../../components/shared-components/gate/SignInGate.component';

import styleSheet from './styles/prediction.module.scss';
import PredictionOption from './predictionOption';
import { sendAnswer } from '../apiRequests';
import { savePredictionSubmitted } from '../predictions.helper';
import PredictionCarouselControls from './predictionCarouselControls';
import { PredictionContext } from './predictionContent';
import { sendGTMEvent } from '../../helpers/gtm';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component which allows fan to vote for an option
 *
 * @param {object} props prediction option props
 * @param {string} props.transitionState transition state
 * @returns {Function} <PredictionVoteScreen />
 */
function PredictionVoteScreen({ transitionState }) {
    const { t } = useTranslation();
    const {
        prediction,
        predictionOptions,
        selectedOptions,
        setSelectedOptions,
        setIsSubmitted,
        transitionStyles,
        isPreview,
        isSignedIn,
        isGated,
        authHeader
    } = useContext(PredictionContext);

    const elementRef = useRef(null);
    const nodeRef = useRef(null);

    const [submittingVote, setSubmittingVote] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const transitionDelay = 100;
    const transitionLength = 300;
    const optionsVisibleDelay =
        predictionOptions.length * transitionDelay + transitionLength;

    const onSelect = (option) => {
        setSelectedOptions([{ ...option }]);
    };

    const onVoteSubmit = async () => {
        if (isPreview) {
            return;
        }

        if (selectedOptions.length > 0) {
            setSubmittingVote(true);

            // Check if selectedOptions contains at least one object (not null)
            // If it does not it means the user has tried to resubmit their vote on new page load
            // without selecting an option - this check allows us to not send a new request if the answer has
            // not changed
            const containsObject = selectedOptions.some(
                (item) => typeof item === 'object' && item !== null
            );

            if (!containsObject) {
                setIsSubmitted(true);
                setSubmittingVote(false);
                return;
            }

            const answerPayload = {
                answer_options: selectedOptions.map((option) => ({
                    option_id: option.option_id ?? null
                }))
            };

            // Sends API requests
            sendAnswer(
                prediction.prediction_id,
                prediction.questions[0].question_id,
                answerPayload,
                isGated,
                authHeader
            )
                .then(() => onSubmitSuccess(answerPayload))
                .catch((error) => {
                    setSubmitError(true);
                    console.error(error.detail ?? error.message);
                });
        } else {
            console.warn('NO OPTION SELECTED!');
        }
    };

    const onSubmitSuccess = (answerPayload) => {
        setIsSubmitted(true);
        setSubmittingVote(false);
        savePredictionSubmitted(
            prediction.prediction_id,
            answerPayload.answer_options.map((option) => option.option_id)
        );

        submitError && setSubmitError(false);

        sendGTMEvent('playbook_vote_event', prediction, {
            playbookUserVoted: true,
            playbookUserAnswers: selectedOptions.map(
                (option) => option.text || option.option_id
            )
        });
    };

    return (
        <div className={styles.predictionWrapper}>
            {!isSignedIn && isGated && <SignInGate type="prediction" />}

            <PredictionCarouselControls elementRef={elementRef}>
                <div
                    className={styles.predictionVoteContainer}
                    ref={elementRef}
                >
                    {predictionOptions.map((item, i) => {
                        const isSelected = selectedOptions.find(
                            (opt) =>
                                opt.option_id === item.option_id ||
                                opt == item.option_id
                        );
                        return (
                            <div
                                key={i}
                                className={styles.predictionOptionItem}
                                ref={nodeRef}
                            >
                                <PredictionOption
                                    option={item}
                                    transitionState={transitionState}
                                    transitionStyles={transitionStyles}
                                    optionsVisibleDelay={optionsVisibleDelay}
                                    onSelect={onSelect}
                                    isSelected={!!isSelected}
                                />
                            </div>
                        );
                    })}
                </div>
            </PredictionCarouselControls>

            <div
                className={styles.predictionSubmitContainer}
                style={{
                    ...transitionStyles[transitionState],
                    transitionDelay: `${transitionDelay}ms`
                }}
            >
                <SubmitButton
                    onSubmit={onVoteSubmit}
                    disabled={selectedOptions.length === 0}
                    title={t('predictions.saveButton')}
                    buttonText={t('predictions.saveButton')}
                    loading={submittingVote}
                />
            </div>

            {submitError && (
                <p className={styles.predictionWarningText}>
                    * {t('predictions.saveError')}
                </p>
            )}
        </div>
    );
}

PredictionVoteScreen.propTypes = {
    transitionState: PropTypes.string.isRequired
};

export default PredictionVoteScreen;
