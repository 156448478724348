import { useContext } from 'preact/hooks';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Countdown from '../Countdown/Countdown.component';
import PollOptionsList from './pollOptionsList.component';
import PollTotalVotes from '../PollTotalVotes/pollTotalVotes.component';
import ShareUrlBox from '../Share/ShareUrlBox.component';
import OptionAttributes from '../H2H/OptionAttributes.component';
import TriviaAnswerText from '../TriviaAnswerText/triviaAnswerText.component';
import TriviaWrongAnswer from './freeText.TriviaWrongAnswer.component';
import SignInGate from '../shared-components/gate/SignInGate.component';

import { PollContext } from '../../polls/poll.component';
import { POLL_TYPES } from '../../helpers/constants';

import styleSheet from './freeText.module.scss';
import pollStyleSheet from './poll.module.scss';
const styles = styleSheet.locals || {};
const pollStyles = pollStyleSheet.locals || {};

/**
 * Component to show poll results
 *
 * @param {object} props poll option props
 * @param {number} props.transitionDelay transition delay
 * @param {string} props.transitionState transition state
 * @returns {Function} <PollResultsScreen />
 */
function PollResultsScreen({ transitionDelay, transitionState }) {
    const {
        poll,
        pollOpen = false,
        showVotesCounter = false,
        transitionStyles,
        totalVotes = 0,
        onCountdownEnd,
        isGated,
        isSignedIn
    } = useContext(PollContext);
    const { t } = useTranslation();
    const isH2H = poll.type === POLL_TYPES.H2H;
    const isTrivia =
        (poll?.type === POLL_TYPES.TRIVIA ||
            poll?.type === POLL_TYPES.FREE_TEXT) ??
        false;

    const isFreeText = poll?.type === POLL_TYPES.FREE_TEXT;

    const checkFanAnswer = (fanAnswerIds, correct_options) => {
        // If no selected options or no correct options, return false
        if (!fanAnswerIds || !correct_options) {
            return { allCorrect: false, correctCount: 0 };
        }

        // Convert fanAnswerIds to an array
        const fanAnswersArray = fanAnswerIds.split(',');

        // Count of correct answers
        let correctCount = 0;

        // Check if every correct option is included in the fan answer array
        correct_options.forEach((correctOption) => {
            if (fanAnswersArray.includes(correctOption.option_id.toString())) {
                correctCount++;
            }
        });

        // Check if all correct options are included and no extra options are present
        return {
            allCorrect:
                correctCount === correct_options.length &&
                fanAnswersArray.length === correct_options.length,
            correctCount,
            didFanAnswer: fanAnswerIds?.length > 0
        };
    };

    const storedAnswer = localStorage.getItem(poll.trivia_id);

    const isFanCorrect = checkFanAnswer(
        storedAnswer,
        poll?.questions?.[0]?.correct_options
    );

    const isClosed = poll?.state === 'closed';

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <style>{pollStyleSheet.toString()}</style>

            <div className={pollStyles.pollWrapper}>
                {isGated && !isSignedIn && <SignInGate />}

                {isFreeText && !isFanCorrect?.allCorrect && !isClosed && (
                    <TriviaWrongAnswer
                        userAnswer={localStorage.getItem(
                            `userAnswer_${poll.trivia_id}`
                        )}
                    />
                )}

                {isFreeText && (
                    <p className={styles.freetextIncorrectHelp}>
                        {t('polling.correctAnswer')}:
                    </p>
                )}

                <PollOptionsList />

                {isH2H && (
                    <OptionAttributes options={poll.questions[0].options} />
                )}
                {isTrivia && <TriviaAnswerText isFanCorrect={isFanCorrect} />}
            </div>

            {showVotesCounter && (
                <PollTotalVotes
                    votes={totalVotes}
                    margin={true}
                    style={{
                        ...transitionStyles[transitionState]
                    }}
                    isTrivia={isTrivia}
                />
            )}

            {pollOpen && poll.closes_at && (
                <Countdown
                    text={t('polling.closesIn')}
                    datetime={poll.closes_at}
                    onCountdownEnd={onCountdownEnd}
                    closes={true}
                />
            )}

            {pollOpen && (
                <ShareUrlBox
                    pollId={isTrivia ? poll?.trivia_id : poll.poll_id}
                    style={{
                        ...transitionStyles[transitionState],
                        transitionDelay: `${transitionDelay}ms`
                    }}
                />
            )}
        </>
    );
}

PollResultsScreen.propTypes = {
    transitionDelay: PropTypes.number.isRequired,
    transitionState: PropTypes.string.isRequired
};

export default PollResultsScreen;
