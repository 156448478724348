import { getClientHost } from './client';
import { getOrCreateEncodedUUID } from './user-meta';
import { POLL_TYPES } from './constants';

/**
 * POST /activation/{activation_id}/view
 * Submit a view to the activation
 *
 * @param {string} type - which product (POLL/TRIVIA/PREDICTOR)
 * @param {string} activationId - the ID of the activation registering the view (poll/trivia/predictor id)
 * @param {string} isGated - controls which endpoint to hit, sso gated or normal
 * @param {string} authHeader - gated auth token
 * @returns {Promise} -
 */
export const sendView = (type, activationId, isGated, authHeader) => {
    const product = POLL_TYPES[type];

    const { POLL, TRIVIA, PREDICTIONS } = POLL_TYPES;

    let path;
    let activationPath;

    if (product === POLL) {
        path = process.env.API_PATH;
        activationPath = 'polls';
    } else if (product === TRIVIA) {
        path = process.env.API_PATH_TRIVIA;
        activationPath = 'trivia';
    } else if (product === PREDICTIONS) {
        path = process.env.API_PATH_PREDICTIONS;
        activationPath = 'predictions';
    }

    const HOST = `${getClientHost()}${path}`;

    const endpoint = isGated
        ? `https://${HOST}/gated/${activationPath}/${activationId}/view`
        : `https://${HOST}/${activationPath}/${activationId}/view`;

    const userId = getOrCreateEncodedUUID();
    const headersConfig = {
        'Content-Type': 'application/json'
    };

    if (isGated && authHeader) {
        headersConfig.Authorization = authHeader;
    }

    if (!isGated && userId) {
        headersConfig['X-User-Id'] = userId;
    }

    return new Promise((resolve, reject) => {
        fetch(endpoint, {
            method: 'POST',
            headers: headersConfig
        })
            .then((response) => {
                if (
                    response.status &&
                    !response.status.toString().match(/2\d{2}/g)
                ) {
                    // Non sucessful response
                    reject(response);
                    return;
                }
                resolve(response);
            })
            .catch((error) => {
                console.error('Playbook - Poll view unsuccessful.', error);
                reject(error);
            });
    });
};
